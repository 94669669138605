<template>
    <div
        data-input-group="password"
        class="input-group"
        :class="{ 'input-group--error': error }"
    >
        <div class="input-group__wrapper">
            <input
                name="password"
                placeholder="Пароль"
                class="input"
                :type="typeInput ? 'text' : 'password'"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <div class="btn" @click.prevent="togglePassword">
                <img src="/img/icons/eye-1.svg" v-show="typeInput" />
                <img src="/img/icons/eye-closed.svg" v-show="!typeInput" />
            </div>
        </div>
        <div class="input-group__message input-group__error" v-if="error">
            {{ typeof error === 'string' ? error : error[0] }}
        </div>
        <slot />
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: {
        error: [String, Array]
    },

    setup() {
        const typeInput = ref(false);
        const togglePassword = () => (typeInput.value = !typeInput.value);

        return { typeInput, togglePassword };
    }
};
</script>
