<template>
    <ButtonBack :assignClass="'card__btn-back'" />

    <img class="card__avatar" src="/img/icons/learner.svg" v-if="userRole === 'student'" />
    <img class="card__avatar" src="/img/icons/customer.svg" v-else />

    <div class="card__title title-second">
        Вход в личный кабинет
        <div class="text-blue title-second">{{ userName }}</div>
    </div>
    <div class="card__description">
      Ведите логин и пароль – находятся в электронном почтовом ящике
        <span class="text-gray"
            >(Входящее сообщение «Регистрация на портале»)</span
        >
    </div>
    <form
        class="card__form"
        @submit.prevent="sendRequest"
        @keyup.enter="sendRequest"
    >
        <Input
            v-if="userRole === 'customer'"
            :type="'email'"
            :placeholder="'E-mail'"
            :error="errors.email"
            @update:modelValue="request.email = $event"
        />

        <InputPassword :error="errors.password" v-model="request.password">
            <div class="input-group__message" v-if="userRole === 'customer'">
                Забыли пароль?&nbsp;
                <span
                    class="input-group__message-link"
                    @click.prevent="$router.push('/login/restore')"
                    >Восстановить</span
                >
            </div>
        </InputPassword>

        <button type="submit" class="btn btn-red">Войти</button>
    </form>
    <BaseModal v-if="isModalOpen" @close="isModalOpen = false">
        <div v-if="errorsLogin">{{ errorsLogin }}</div>
    </BaseModal>
</template>

<script>
import Input from "@/components/form/input";
import InputPassword from "@/components/form/input-password";
import BaseModal from '@/components/modals/base-modal';

export default {
    components: {
        Input,
        InputPassword,
        BaseModal
    },

    data() {
        return {
            userRole: this.$route.name,
            request: {
                role: this.$route.name,
                email: "",
                password: ""
            },
            errors: {},
            isModalOpen: false,
        };
    },

    computed: {
        userName() {
            return this.userRole === "student" ? "Обучаемого" : "Заказчика";
        },
        errorsLogin() {
            return this.$store.state.personal.errors
        }
    },

    methods: {
        sendRequest() {
            this.$store
                .dispatch("AUTH_LOGIN_REQUEST", this.request)
                .then(() => {
                    this.$router.push("/personal");
                })
                .catch(error => {
                    if (error.response.data.errors) {
                      this.errors = error.response.data.errors;
                    }

                    if (error.response.data['email-confirm-message']) {
                      this.$store.commit('PERSONAL_GET_ERROR', error.response.data['email-confirm-message'])
                    }
                    // if (this.request.email) this.request.email = "";
                    // this.request.password = "";
                });
        }
    },

    mounted() {
        if (this.userRole === "student") delete this.request.email;
    },

    watch: {
        errorsLogin(newValue) {
            if (newValue) this.isModalOpen = true
        }
    }
};
</script>
